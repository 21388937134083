import React from 'react';
import ReactWOW from 'react-wow';
import styles from './interest.module.css';
import Media from 'react-bootstrap/Media';

export default ({ title, icon, wowEffect, children }) => (
  <ReactWOW duration="1s" animation={wowEffect}>
    <Media className={styles.interest}>
      <div className="icon">{icon}</div>
      <Media.Body>
        <h5>{title}</h5>
        <p>
          {children}
        </p>
      </Media.Body>
    </Media>
  </ReactWOW>
)
