import React from 'react';
import ReactWOW from 'react-wow';
// import Img from 'gatsby-image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import backgroundImage from '../../static/images/aboutme/bg.jpg';
import chopperImage from '../../static/images/aboutme/chopperResized.png';
import BlogSection from './section';

const styles = {
  thumbnail: { width: 130, height: 130, backgroundColor: "black" },
  avatar: { width: 120, height: 120, verticalAlign: "middle", borderRadius: "50%" },
}

export default ({ data }) => (
  <BlogSection title="About Me" desc="" backgroundImage={backgroundImage} backgroundColor="#333333">
    <Row>
      <Col md={{ span: 8, offset: 2 }}>
        <ReactWOW duration="1s" animation="fadeInUp">
          <p>{data.shortBio.shortBio}</p>
        </ReactWOW>
      </Col>
    </Row>
    <div className="text-center">
      <span className="text-center">
        <span>
          {/* <Img style={styles.avatar} fluid={data.heroImage.fluid} /> */}
          <img style={styles.avatar} src={chopperImage} alt={data.name} />
        </span>
        <h4>{data.name}</h4>
      </span>
    </div>
  </BlogSection>
)

