import React from 'react';
import ReactWOW from 'react-wow';
// import Img from 'gatsby-image';
// import Image from 'react-bootstrap/Image'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import BlogSection from './section';
import Interest from './interest';
import photo1 from '../../static/images/interest1.jpg';
import photo2 from '../../static/images/interest2.jpg';
import { FaCamera, FaKeyboard } from 'react-icons/fa';
import { GiDoubleFish, GiSwimfins } from 'react-icons/gi';
import { BiCameraMovie } from 'react-icons/bi';

export default ({ data }) => (
  <BlogSection title="My Interests" desc="" textColor="black" backgroundColor="white">
    <Row>
      <Col sm={{ span: 6 }}>
        <ReactWOW duration="3s" animation="fadeIn">
          <section>
            <img src={photo1} alt="Sai Kung" style={{ width: "100%" }} />
          </section>
        </ReactWOW>
        <Interest title="Japan Animation / TV" wowEffect="bounceInLeft" icon={(<BiCameraMovie />)}>
          I love japan animation and I am One Piece fans! Also I read/watch japan comic/animation
          such as Dragan Ball, Saint, Naruto, Ginama..etc. For TV, I like watching science
          fiction, supernatural, stuff. My favorite TV show:Game of Thrones, Supernatural, Doctor
          Who, Walking Dead, Flash, Arrow, Magician ..etc
        </Interest>
        <Interest title="Programming" wowEffect="bounceInLeft" icon={(<FaKeyboard />)}>
          I got 20+ years experience on enterprise application development, interested in web / mobile app
          . In my spare time I will use my skills to develop some application for fun.
        </Interest>
      </Col>
      <Col sm={{ span: 6 }} >
        <Interest title="Sea lover ~ Fishing, Swimming, diving" wowEffect="bounceInRight" icon={(<GiSwimfins />)}>
          I grow up in Sai Kung (east of HK), my parents and I love the sea a lot.
          Once we have time we will drive our boat around Sai Kung Sea and having outdoor activity.
        </Interest>
        <Interest title="Photograph" wowEffect="bounceInRight" icon={(<FaCamera />)}>
          I own a Canon DSLR, I like take photo during vocation with my family.
        </Interest>
        <Interest title="Aquarium" wowEffect="bounceInRight" icon={(<GiDoubleFish />)}>
          I started my Aquarium in year 2001 (It was a 12 inchs small fish tank).
          I upgraded my fish tank from time to time and right now it is a 48 inch tank.
        </Interest>
        <ReactWOW duration="3s" animation="fadeIn">
          <section>
            <img src={photo2} alt="Fish Tank" style={{ width: "100%" }} />
          </section>
        </ReactWOW>
      </Col>
    </Row>
  </BlogSection>
)

