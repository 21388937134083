import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Layout from '../components/layout'
import AboutMe from '../components/about-me'
import MyInterest from '../components/my-interest'
import Carousel from '../components/carousel'
import GetInTouch from '../components/get-in-touch'
import ArticlePreview from '../components/article-preview'
// import mousescroll from '../../static/libs/mousescroll.js'
// import smoothscroll from '../../static/libs/smoothScroll0.9.9/smoothscroll.js'
// import jqueryInview from '../../static/libs/jquery.inview.min.js'
// import isotope from '../../static/libs/isotope3.0.3/isotope.pkgd.min.js'
// import prettyPhoto from '../../static/libs/prettyPhoto3.1.6/js/jquery.prettyPhoto.js'
// import animateCss from '../../static/libs/animate3.5.1/animate.min.css'
// import prettyPhotoCss from '../../static/libs/prettyPhoto3.1.6/css/prettyPhoto.css'

class RootIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const posts = get(this, 'props.data.allContentfulBlogPost.edges')
    const carousel = get(this, 'props.data.allContentfulCarousel.edges')
    const [author] = get(this, 'props.data.allContentfulPerson.edges')

    console.log('boat ', carousel);
    return (
      <Layout location={this.props.location}>
        <div style={{ background: '#fff' }}>
          <Helmet title={siteTitle}>
            {/* 
            <script src={mousescroll}></script>
            <script src={smoothscroll}></script>
            <script src={jqueryInview}></script>
            <script src={isotope}></script>
            <script src={prettyPhoto}></script>
            {/* <link href={prettyPhotoCss} rel="stylesheet" /> */}
          </Helmet>
          <Carousel data={carousel} carouselHeight="450" />
          <MyInterest data={author.node} />
          <AboutMe data={author.node} />
          <GetInTouch />
          <div className="wrapper">

            <h2 className="section-headline">Recent articles</h2>
            <ul className="article-list">
              {posts.map(({ node }) => {
                return (
                  <li key={node.slug}>
                    <ArticlePreview article={node} />
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery {
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          tags
          heroImage {
            fluid(maxWidth: 350, maxHeight: 196, resizingBehavior: SCALE) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allContentfulCarousel(sort: { fields: [displayOrder], order: ASC }) {
      edges {
        node {
          displayOrder
          title
          description
          titleColor
          image: image {
            fluid (
              maxWidth: 2500
              maxHeight: 1080              
            ) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
        }
      }
    }
    allContentfulPerson(
      filter: { contentful_id: { eq: "15jwOBqpxqSAOy2eOO4S0m" } }
    ) {
      edges {
        node {
          name
          shortBio {
            shortBio
          }
          title
          heroImage: image {
            fluid(
              maxWidth: 1180
              maxHeight: 480
              resizingBehavior: PAD
              background: "rgb:000000"
            ) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
        }
      }
    }
  }
`
