import React from 'react';
import ReactWOW from 'react-wow';
import styles from './section.module.css';
import Container from 'react-bootstrap/Container';

const style2 = {
  section: (textColor, backgroundColor, backgroundImage) => ({
    background: backgroundImage ? " url(" + backgroundImage + ") no-repeat 0 0" : backgroundColor,
    backgroundSize: "cover",
    paddingTop: 85,
    paddingBottom: 85,
    paddingLeft: 0,
    paddingRight: 0,
    color: textColor ? textColor : "white"
  })
}

export default ({ title, desc, children, textColor, backgroundColor, backgroundImage }) => (
  <section style={style2.section(textColor, backgroundColor, backgroundImage)}>
    <Container>
      <ReactWOW animation="fadeInDown">
        <div className={styles.sectionHeader}>
          {title && (
            <h1 className="section-title text-center">{title}</h1>
          )}
          <p className="text-center wow fadeInDown">{desc}</p>
        </div>
      </ReactWOW>
      {children}
    </Container>
  </section >
)

