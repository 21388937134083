import React from 'react';
import ReactWOW from 'react-wow';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import getInTouchStyles from './get-in-touch.module.css';
import sectionStyles from './section.module.css';
import Container from 'react-bootstrap/Container';
import mapImage from '../../static/images/sai-kung-map.png';

const styles = {
  map: {
    background: " url(" + mapImage + ") no-repeat 0 0",
    backgroundSize: "cover",
    height: 650
  }
}

export default ({ data }) => (
  <>
    <section className={getInTouchStyles.section}>
      <Container>
        <ReactWOW animation="fadeInDown">
          <div className={sectionStyles.sectionHeader}>
            <h1 className="section-title text-center">Get In Touch</h1>
          </div>
        </ReactWOW>
      </Container>
    </section>
    <section style={styles.map}>
      <Container className={getInTouchStyles.formContainer}>
        <Row>
          <Col sm={{ span: 6, offset: 6 }}>
            <div className={getInTouchStyles.contactForm}>
              <h3>Contact us</h3>
              <form>
                <div className="form-group">
                  <input type="text" name="name" className="form-control" placeholder="Name" required />
                </div>
                <div className="form-group">
                  <input type="email" name="email" className="form-control" placeholder="Email" required />
                </div>
                <div className="form-group">
                  <input type="text" name="subject" className="form-control" placeholder="Subject" required />
                </div>
                <div className="form-group">
                  <textarea name="message" className="form-control" rows="8" placeholder="Message" required />
                </div>
                <div style={{ textAlign: 'center' }} >
                  <button type="submit" className="btn btn-primary">Send Message</button>
                </div>
              </form>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  </>
)

