import React, { useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { VscChevronLeft, VscChevronRight } from 'react-icons/vsc';
import { Carousel as ReactCarousel } from 'react-responsive-carousel'
import boat1 from '../../static/images/slider/boat1.jpg'
import boat3 from '../../static/images/slider/boat3.jpg'
import boat4 from '../../static/images/slider/boat4.jpg'
import boat5 from '../../static/images/slider/boat5.jpg'
import boat6 from '../../static/images/slider/boat6.jpg'
import styles from './carousel.module.css'

// const boats = [{
//   title: 'Kaito Ferry #1',
//   desc: 'Blue kai-to ferry - this photo was took around 1981-86.',
//   color: '#45aed6',
//   image: boat1
// },
// {
//   title: 'Kaito Ferry #2',
//   desc: 'It was another Kai-to ferry which provide rental service for fishing, swimming or squid fishing at night. (Sold in 2007)',
//   color: 'white',
//   image: boat3
// },
// {
//   title: '15 HP Speed Boat',
//   desc: 'This boat had a nick name called Snail, it had P license which allow using fish tool (e.g. fish net)',
//   color: 'white',
//   image: boat4
// },
// {
//   title: 'My first speed boat (170 HP)',
//   desc: 'This boat was purchased in 2008. It had a 170 HP Engine and nick name called greedy. It burned gas really quick and it was sold already.',
//   color: 'white',
//   image: boat5
// },
// {
//   title: '60 HP Speed Boat',
//   desc: 'This is second boat purchased in 2015. It has a 60 HP engine and good for fishing.',
//   color: 'white',
//   image: boat6
// }];

const styles2 = {
  item: {
    position: "absolute",
    width: "100%",
    textAlign: "left",
    marginTop: 30
  },
  image: (backgroundImage, imageHeight) => ({
    background: " url(" + backgroundImage + ") no-repeat 0 0",
    backgroundSize: "cover",
    height: imageHeight,
    backgroundPosition: "center bottom"
  }),
  imageDesc: (titleColor) => ({
    color: titleColor,
    marginLeft: 10
  }),
  footer: {
    padding: 30,
    backgroundColor: "#eeeeee"
  }
}

export default function Carousel({ data }) {
  const [currentSlide, setCurrentSlide] = useState(0);

  const next = () => {
    setCurrentSlide(currentSlide + 1);
  }

  const prev = () => {
    setCurrentSlide(currentSlide - 1);
  }

  const updateCurrentSlide = (index) => {
    setCurrentSlide(index);
  }
  const carouselHeight = 500;

  return (
    <section className={styles.carousel}>
      <div className="control-container" style={{ height: carouselHeight }}>
        <div>
          <button className="control-prev" onClick={prev}><VscChevronLeft /></button>
          <button className="control-next" onClick={next}><VscChevronRight /></button>
        </div>
      </div>
      <ReactCarousel showStatus={false} showIndicators={false} showThumbs={false} emulateTouch={true}
        infiniteLoop={true} autoPlay={true} showArrows={false}
        onChange={updateCurrentSlide}
        selectedItem={currentSlide}
      >
        {data.map(function ({ node }) {
          return (
            <div key={node.displayOrder} style={styles2.image(node.image.fluid.src, carouselHeight)}>
              <div style={styles2.item}>
                <Row>
                  <Col sm={{ span: 10, offset: 1 }}>
                    <h2 style={styles2.imageDesc(node.titleColor)}>{node.title}</h2>
                    <p style={styles2.imageDesc(node.titleColor)}>{node.description}</p>
                  </Col>
                </Row>
              </div>
            </div>)
        })}
      </ReactCarousel>
      <section style={styles2.footer}>
        <Row >
          <Col sm={{ span: 10, offset: 1 }}>
            <p>Welcome to vicada.com!<br />
             This website is my personal website and just for fun only.</p>
          </Col>
        </Row>
      </section>
    </section>
  )
}
